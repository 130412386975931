//////////////////////////////
// CARD

.card {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	background-color: var(--elements-bg);
	border-radius: 0.5rem;
	box-shadow: 0 0 2rem rgba(43, 57, 69, 0.15);
	transition: box-shadow 0.4s ease-in-out;

	&:hover {
		box-shadow: 0 1rem 2rem rgba(43, 57, 69, 0.25);
	}

	@include breakpoint(mobile-lg) {
		width: 75%;
		margin: 0 auto;
	}

	@include breakpoint(mobile-sm) {
		width: 90%;
		margin: 0 auto;
	}

	&__image {
		height: 16rem;
		border-radius: 0.5rem 0.5rem 0 0;
		box-shadow: 0 0 1rem rgba(43, 57, 69, 0.06);

		@include breakpoint(tablet-portrait) {
			height: 18rem;
		}

		@include breakpoint(mobile-sm) {
			height: 15rem;
		}

		& img {
			width: 100%;
			height: 16rem;
			object-fit: cover;
			border-radius: 0.5rem 0.5rem 0 0;
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0 2rem 2rem;
	}

	&__title {
		font-size: 1.85rem;
		line-height: 1.4;
		margin-bottom: 1.2rem;

		@include breakpoint(mobile-lg) {
			margin-bottom: 0;
		}

		@include breakpoint(mobile-sm) {
			font-size: 1.6rem;
			line-height: 1.2;
		}
	}

	&__data {
		margin-top: auto;

		& > * + * {
			margin-top: 0.2rem;
		}

		& p {
			font-weight: 700;
		}

		& span {
			font-weight: 400;
		}
	}
}
