//////////////////////////////
// TYPOGRAPHY

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;600;700&display=swap');

body {
	font-family: $nunito;
	font-size: $homepage-items;
	font-weight: 400;
	line-height: 1.5;
	background-color: var(--body-bg);
	color: var(--text-color);
	overflow-y: scroll;
}

h1,
h2,
h3 {
	font-weight: 700;
}

a {
	text-decoration: none;
	color: inherit;
}
