//////////////////////////////
// COUNTRY

.country {
	padding-top: 5rem;
	padding-bottom: 5rem;

	&__back {
		background-color: var(--elements-bg);
		display: inline-flex;
		align-items: center;
		gap: 1rem;
		padding: 0.6rem 2.5rem;
		box-shadow: 0 0 0.2rem 0.1rem rgb(43 57 69 / 0.15);
	}

	&__arrow {
		width: 1.4rem;
		height: 1.4rem;
		fill: var(--text-color);
	}

	&__text {
		font-size: 1.6rem;
	}

	&__detail {
		position: relative;
		display: grid;
		grid-template-columns: 35% 1fr;
		gap: 12rem;
		margin-top: 8rem;

		@include breakpoint(tablet-landscape) {
			gap: 4rem;
		}

		@include breakpoint(tablet-portrait) {
			grid-template-columns: 1fr;
		}
	}

	&__flag img {
		display: block;
		max-width: 100%;
		height: 28rem;
		object-fit: cover;
		box-shadow: 0 0 0.2rem rgb(43 57 69 / 0.1);

		@include breakpoint(tablet-landscape) {
			height: 24rem;
		}

		@include breakpoint(mobile-lg) {
			width: 100%;
			max-height: 100%;
		}
	}

	&__info {
		& h2 {
			font-size: 2.6rem;
			line-height: 1;
			margin-bottom: 1.5rem;

			@include breakpoint(tablet-portrait) {
				font-size: 2.2rem;
				line-height: 1.2;
			}

			@include breakpoint(mobile-sm) {
				font-size: 2rem;
			}
		}

		& p {
			font-weight: 700;

			& span {
				font-weight: 400;
			}
		}
	}

	&__details {
		display: grid;
		grid-template-columns: max-content 1fr;
		gap: 6rem;

		@include breakpoint(mobile-lg) {
			grid-template-columns: 1fr;
			gap: 0;
		}
	}

	&__right {
		justify-self: end;

		@include breakpoint(mobile-lg) {
			justify-self: start;
		}
	}

	&__border {
		display: grid;
		grid-template-columns: max-content 1fr;
		gap: 1.5rem;
		margin-top: 3rem;

		@include breakpoint(mobile-lg) {
			grid-template-columns: 1fr;
		}
	}

	&__borders {
		display: flex;
		flex-wrap: wrap;
		gap: 1.8rem;
	}

	&__link {
		background-color: var(--elements-bg);
		padding: 0.6rem 2rem;
		box-shadow: 0 0 0.2rem 0.1rem rgb(43 57 69 / 0.15);
	}
}
